import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SuccessView from '../views/SuccessView.vue'
import CancelledView from '../views/CancelledView.vue'
import SentRequestView from '../views/SentRequestView.vue'
import CondizioniServizioView from '../views/CondizioniServizioView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home Page',
      description: 'Homepage'
    }
  },
  {
    path: '/',
    name: 'success',
    alias: '/success',
    component: SuccessView,
    meta: {
      title: 'Success page',
      description: 'Success page'
    }
  },
  {
    path: '/',
    name: 'cancel',
    alias: '/cancel',
    component: CancelledView,
    meta: {
      title: 'Cancel',
      description: 'Cancel page'
    }
  },
  {
    path: '/',
    name: 'sent-request',
    alias: '/sent-request',
    component: SentRequestView,
    meta: {
      title: 'Sent Request',
      description: 'Sent request page'
    }
  },
  {
    path: '/',
    name: 'condizioni-servizio',
    alias: '/condizioni-servizio',
    component: CondizioniServizioView,
    meta: {
      title: 'Condizioni Servizio',
      description: 'Condizioni Servizio page'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
