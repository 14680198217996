<template>
  <div>

    <div class="booking-section-row">
            <button class="back-button" @click="goBack">
                <img src="@/assets/page-left.png" alt="" class="img-page-left">
            </button>
        </div>

    <v-select
      :label="$t('message.booking.nationality')"
      item-title="title"
      item-value="code"
      :items="countriesOptions"
      v-model="nationality"
      :clearable="true"
    >
    </v-select>

    <div class="booking-section-row">
      <p>{{ $t("message.booking.tooltip1") }}</p>
      <p>{{ $t("message.booking.tooltip2") }}</p>
      <v-text-field
        :label="$t('message.booking.bookingNumber')"
        v-model="bookingNumber"
      >
      </v-text-field>
    </div>
    <div class="booking-section-row">
      <v-text-field
        :label="$t('message.booking.email')"
        v-model="customer_email"
      >
      </v-text-field>
    </div>
    <div class="booking-section-row">
      <v-text-field
        :label="$t('message.booking.fullName')"
        v-model="customer_name"
      >
      </v-text-field>
    </div>
    <div class="booking-section-row">
      <MazPhoneNumberInput
        v-model="phone"
        style="width: 100%; margin-bottom: 20px"
        v-model:country-code="countryCode"
        show-code-on-list
        :preferred-countries="['IT', 'FR', 'BE', 'DE', 'US', 'GB']"
        @update="language = $event"
        :translations="{
          countrySelector: {
            placeholder: 'Country code (*)',
            error: 'Choose country',
            searchPlaceholder: 'Search a country',
          },
          phoneInput: {
            placeholder: 'Phone number (*)',
            example: 'Example:',
          },
        }"
      />
    </div>

    <div class="checkbox-terms">
      <v-checkbox v-model="checkbox"></v-checkbox>

      <div class="terms-box">
        <v-dialog max-width="500">
          <template v-slot:activator="{ props: activatorProps }">
            <span
              class="terms-conditions"
              style="color: #1c4c34"
              v-bind="activatorProps"
              id="terms-coditions-span"
            >
              {{ $t("message.booking.terms") }}</span
            >
          </template>
          <template v-slot:default="{ isActive }">
            <v-card max-width="500">
              <template v-slot:title>
                <div>
                  <span>{{ $t("message.booking.termsTitle") }}</span>
                  <v-icon @click="isActive.value = false" class="close-button"
                    >mdi-close</v-icon
                  >
                </div>
              </template>
              <v-card-text>
                <CondizioniServizioView></CondizioniServizioView>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="CLOSE" @click="isActive.value = false"></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </div>
    <div
      v-if="fullPrice && discountedPrice"
      class="booking-section-row price-preview"
    >
      <span style="color: red; text-decoration: line-through"
        >{{ fullPrice }} &#8364;</span
      >
      <span>{{ discountedPrice }} &#8364;</span>
    </div>
    <v-btn
      class="baggy-button"
      @click="checkout"
      elevation="2"
      text
      color="#1c4c34"
      :loading="loading"
      :disabled="!mandatoryFieldsFilled"
    >
      {{ $t("message.booking.proceed") }}
    </v-btn>
  </div>
</template>

<script>
import { allCountries } from "country-region-data";
import CondizioniServizioView from "../views/CondizioniServizioView.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

export default {
  name: "BookingSectionV2",
  props: {
    loading: Boolean,
    discountedPrice: Number,
    fullPrice: Number,
  },
  components: {
    CondizioniServizioView,
    MazPhoneNumberInput,
  },
  computed: {
    mandatoryFieldsFilled() {
      var validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var validCustomerName = /[A-Za-z]/;

      return (
        this.customer_email !== null &&
        this.customer_email.match(validEmailRegex) &&
        this.customer_name !== null &&
        this.customer_name.match(validCustomerName) &&
        this.checkbox &&
        this.language.isValid
      );
    },
  },

  data() {
    return {
      countriesOptions: [],
      nationality: null,
      customer_email: null,
      customer_name: null,
      phone: null,
      language: null,
      checkbox: false,
      bookingNumber: null,
    };
  },
  mounted() {
    this.countriesOptions = allCountries.map((item) => {
      return {
        title: item[0],
        code: item[1],
      };
    });
  },
  methods: {
    checkout() {
      const payload = {
        nationality: this.nationality,
        bookingNumber: this.bookingNumber,
        customer_email: this.customer_email,
        customer_name: this.customer_name,
        phone: this.phone,
        language: this.$i18n.locale,
      };
      this.$emit("secondStep", payload);
    },

    goBack () {
            this.$emit('goBack')
        }


    
  },
};
</script>

<style scoped>
.booking-section-row p {
  font-size: 16px;
  padding: 0 2px 2px 2px;
  margin: 0;
}

.v-input__details {
  min-height: 0;
  padding: 0;
  display: none;
  grid-area: none;
}

.checkbox-terms {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.baggy-button {
  color: #1c4c34;
  width: 100%;
}

.terms-box {
  text-align: start;
  cursor: pointer;
}

.price-preview {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.price-preview span {
  font-size: 30px;
}

.back-button {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

}

.back-button .img-page-left{
    width: 7%;
}

@media (min-width:1630px){
    .checkbox-terms{
        justify-content: start;
    }
    .terms-box{
        text-align: center;
        padding-bottom: 1.35vw;
    }
}

@media (min-width:625px) and (max-width:1000px){
    .checkbox-terms{
        justify-content: start;
    }
    .terms-box{
        text-align: center;
        padding-bottom: 2.6vw;
    }
}

</style>
