<template>
  <!-- :class="{'gm-apAutocomplete': !isAddressWrong, 'gm-autocomplete-wrong': isAddressWrong}" -->
  <GMapAutocomplete
    class="gm-autocomplete"
    :placeholder="placeholder"
    :options="autocompleteOptions"
    @input="onInputChange"
    @focus="onFocus"
    @blur="onBlur"
    @place_changed="onPlaceChanged"
  >
  </GMapAutocomplete>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "autocompleteMilano",
  props: {
    placeholder: {
      type: String,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  beforeCreate() {
    // Codice eseguito subito prima che la componente venga creata
    this.localPlaceholder = this.placeholder;
  },

  setup(props, { emit }) {
    const { t } = useI18n();

    // Riferimento all'elemento autocomplete
    const selectedAddress = ref("");
    const input = ref("");
    const localPlaceholder = ref(props.placeholder); //placeholder di default quando il componente viene cliccato per la prima volta
    const isAddressWrong = ref(false);

    const onInputChange = (event) => {
      if (event.target.value == "") {
        console.log("Input vuoto");
        //se l'input è ""
        emit("address-selected", t("message.bookingSectionV1.selectPlace")); //cambio il placeholder
      }
      if (event.target.value) {
        // se l'input esiste
        selectedAddress.value = "";
        emit("address-selected", "wrong");
        input.value = event.data;

        isAddressWrong.value = true;
        emit("wrong-address", true);
      }
    };

    const onPlaceChanged = (place) => {
      //funizione che appartiene alle api di google; place indica il valore scelto dalla tendina
      isAddressWrong.value = false;
      emit("wrong-address", false);
      selectedAddress.value = place.formatted_address; // formatto il valore scelto come via (stringa)
      emit("address-selected", selectedAddress.value);
    };

    const onFocus = (event) => {
      //quando pigio sulla componente
      localPlaceholder.value = ""; // il placeholder divents "", nulla c'è piu scritto in questo preciso momento
      event.target.placeholder = localPlaceholder.value; // Forza il re-render del componente se non aggiorna automaticamente

      if (isAddressWrong.value == true) {
        //se la via è gia sbagliata
        emit("wrong-address", true);
      }
    };

    // Opzioni per limitare la ricerca a Palermo
    const autocompleteOptions = ref({
      componentRestrictions: { country: "IT" }, // Limita all'Italia
      types: ["geocode", "establishment"], // Limita ai risultati di tipo indirizzo
      bounds: {
        north: 45.71474686985534, // Coordinata nord: Castelletto Sopra Ticino
        south: 45.316482394275106, // Coordinata sud: Vigevano
        east: 9.3239755, // Coordinata est: Melegnano
        west: 8.637861372681899, // Coordinata ovest: Castelletto Sopra Ticino
      }, // Limita all'area geografica di Milano
      strictBounds: true, // Assicura che la ricerca rimanga entro i limiti
    });

    const onBlur = (event) => {
      if (
        input.value == null ||
        input.value == undefined ||
        input.value == ""
      ) {
        localPlaceholder.value = props.placeholder; //placeholde di defoult
        event.target.placeholder = localPlaceholder.value; //forzo il cambio del placeholder
      } else {
        localPlaceholder.value = props.placeholder;
        event.target.placeholder = localPlaceholder.value;
      }
      emit("address-selected", selectedAddress.value);
    };

    return {
      autocompleteOptions,
      selectedAddress,
      onInputChange,
      onFocus,
      onBlur,
      onPlaceChanged,
    };
  },
};
</script>

<style>
.gm-autocomplete {
  text-align: start;
  font-size: 0.9rem;
  font-family: Wotfard-SemiBold;
  width: 100%;
  cursor: pointer;
  color: #1c4c34;
  text-overflow: ellipsis; /* Aggiunge "..." alla fine del testo se è troppo lungo */
  border-bottom: 1px solid #1c4c34;
 /*  border-radius: 5px; */

  margin-bottom: 4px;
  padding: 5px 0 5px 5px;
}

::placeholder {
  color: #1c4c34;
}

@media (max-width: 1000px) {
  .gm-autocomplete {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .gm-autocomplete {
    font-size: 16px;
  }
}
</style>
