<template>
  <div class="calendar-template">
    <!-- Display days -->
    <h3>{{ localTitolo.value }}</h3>
    <div class="days-container">
      <img src="@/assets/left-arrow.png" alt="left-arrow" @click="prevDays" class="nav-button arrow-img">

      <div
        v-for="(day, index) in visibleDays"
        :key="index"
        :class="{
          'day-pc': screenWidth >= 550,
          'day-mobile': screenWidth < 550,
          selected: isSelected(day),
        }"
        @click="selectDay(day)"
      >
        <!-- <div>{{ day.format('ddd') }}</div> -->
        <p>{{ day.format("DD MMM") }}</p>
      </div>
      <img src="@/assets/right-arrow.png" alt="right-arrow" @click="nextDays" class="nav-button arrow-img">
    </div>

    <!-- Display time slots -->
    <div class="slots-box">
      <div class="img-arrows-slot-box">
        <img src="@/assets/left-arrow.png" alt="left-arrow" @click="prevTimeSlot" class="nav-button arrow-img">
      </div>
      <div class="slider-container">
        <div class="time-slot" v-if="!isNextTimeSlot">
          <p
            v-for="(slot, index) in timeSlot1"
            :key="index"
            class="time-slot-button"
            :class="{ 'selected-time': selectedTime === slot }"
            @click="selectTime(slot)"
          >
            {{ slot }}
          </p>
        </div>
        <div class="time-slot" v-if="isNextTimeSlot">
          <p
            v-for="(slot, index) in timeSlot2"
            :key="index"
            class="time-slot-button"
            :class="{ 'selected-time': selectedTime === slot }"
            @click="selectTime(slot)"
          >
            {{ slot }}
          </p>
        </div>
      </div>
      <div class="img-arrows-slot-box">
        <img src="@/assets/right-arrow.png" alt="right-arrow" @click="nextTmeSlot" class="nav-button arrow-img">
      </div>
    </div>

    <button
      class="select-button"
      @click="confirmSelection"
      :disabled="!isSelectedDropTime"
    >
      Select
    </button>
  </div>
</template>

<script>
import moment from "moment";
import { computed } from "vue";

export default {
  name: "calendarComponent2",
  props: {
    pickupDateTime: {
      type: String,
    },
    dropoffDateTime: {
      type: Object,
    },
    titolo: {
      type: String,
    },
  },

  setup(props) {
    const localTitolo = computed(() => {
      const value = props.titolo;
      return {
        value: value,
      };
    });

    const localPickup = computed(() => {
      const date = moment(props.pickupDateTime, "DD-MM-YYYY, HH:mm");
      return {
        isValid: date.isValid(),
        date: date,
      };
    });

    const localDropoff = computed(() => {
      if (!props.dropoffDateTime) return null;
      const date = moment(props.dropoffDateTime, "DD-MM-YYYY, HH:mm");
      return {
        isValid: date.isValid(),
        date: date,
      };
    });

    return {
      localTitolo,
      localPickup,
      localDropoff,
    };
  },

  data() {
    return {
      selectedDay: null,
      selectedTime: null,
      days: [],
      startIndexDays: 0,
      timeSlot1: [],
      timeSlot2: [],
      timeSlotDefault1: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
      ],
      timeSlotDefault2: [
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
      ],
      timeSlotPass1: [],
      timeSlotPass2: [],
      isNextTimeSlot: null,
      isTimeSlotEmpty: null,
      isSelectedDropTime: null,
      screenWidth: window.innerWidth,
      numberDaysVisible: 0,

      now: moment(), //questo è il momento di adesso
      tomorrow: moment().set({ hour: 8, minute: 0, second: 0 }).add(1, "day"), //domani, da adesso
      defaultStartTime: moment().set({ hour: 8, minute: 0, second: 0 }), //inizio attività
      defaultEndTime: moment().set({ hour: 18, minute: 0, second: 0 }), //fine attività
      hoursSlot: 12, //slot delle ore (sono 12 per ogni pagina)
      rangeTime: 2.5, // minuti che intercorrono da adesso al prossimo orario disponibile. ES: sono le 12:00 + 2.5h = 16:30
    };
  },
  computed: {
    visibleDays() {
      // Show only 5 days starting from startIndex

      if (this.screenWidth < 550) {
        return this.days.slice(this.startIndexDays, this.startIndexDays + 4);
      } else {
        return this.days.slice(this.startIndexDays, this.startIndexDays + 5);
      }
    },
    visibleTimeSlots() {
      return this.timeSlot1.slice(0, 5);
    },
  },
  methods: {
    initializeDays() {
      let now = this.now;

      if (this.localPickup.date.isValid()) {
        now = this.localPickup.date;
      }

      // se ci troviamo tra le 16:30 e le 08:00 di domani
      if (
        now.isSameOrAfter(
          this.defaultEndTime.clone().subtract(this.rangeTime, "hour")
        ) &&
        now.isSameOrBefore(this.tomorrow)
      ) {
        if (!this.localPickup.isValid) {
          now.add(1, "days").startOf("day");
        }
      }

      if (!this.isTimeSlotEmpty) {
        if (!this.localPickup.date.isValid()) {
          for (let i = 0; i < 200; i++) {
            this.days.push(moment(now).add(i, "days"));
          }
        }else{
          this.days.push(moment(now));
        }
      } else {
        now = moment().add(1, "days").startOf("day");
        for (let i = 0; i < 200; i++) {
          this.days.push(moment(now).add(i, "days"));
        }
      }
      this.selectedDay = this.days[0]; // Pre-select today's date
    },

    inizializeTimeSlots() {
      // se l'utente ha già scelto una data di pickUp
      if (this.localPickup.date.isValid()) {
        const timeLocalPickup = this.localPickup.date.format("HH:mm");
        const start = moment(timeLocalPickup, "HH:mm").add(150, "minutes");
        const end = this.defaultEndTime;


        console.log(start);
        let count = -1; // Inizializzo un contatore
        let endFirstSlot = null; // inizializzo da dove partire per il secondo set di ore
        // Crea i time slots incrementando di mezz'ora
        for (
          let m = moment(start);
          m.isSameOrBefore(end);
          m.add(30, "minutes")
        ) {
          count++;
          if (count == 12) {
            endFirstSlot = m;
            break;
          }
          this.timeSlot1.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        this.timeSlotPass1 = this.timeSlot1; // memorizzo l'attuale time slots in una variabile che mi serve nell'altra componente calendar

        /* inizializzo timeSlot2 */

        for (
          let m = moment(endFirstSlot).add(30, "minutes");
          m.isSameOrBefore(end);
          m.add(30, "minutes")
        ) {
          this.timeSlot2.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        this.timeSlotPass2 = this.timeSlot2;

        if (this.timeSlot1.length > 0 && this.timeSlot2.length === 0) {
          this.timeSlot1 = this.timeSlotPass1;
          this.timeSlot2 = this.timeSlotPass1;
        }

        if (this.timeSlot2.length === 0 && this.timeSlot1.length === 0) {
          setTimeout(() => {
            this.inizializeTimeSlotsDefauls();
          }, 300);
        }
      }

      if (this.localPickup.isValid === false) {
        // Se la data di partenza non è valida
        let now = this.now;

        //arrotondo l'ora alla mezz'ora successiva es. sono le 16:13, diventa 16:30
        now.minutes() < 30
          ? now
              .add(this.rangeTime , "hour")
              .minutes(30)
              .seconds(0)
          : now.add(this.rangeTime, "hour").minutes(0).seconds(0);

        let start = now;


        //si mi trovo tra le 18:00 e le 08 di domani
        if (now.isSameOrAfter(this.defaultEndTime.clone()) &&
              now.isSameOrBefore(this.tomorrow)
        ){
          start = this.defaultStartTime;
        }
        // se mi trovo dopo le 16:30 di oggi e le 08 di domani
        if (now.isSameOrAfter(this.defaultEndTime.clone().subtract(this.rangeTime, "hours")) &&
              now.isSameOrBefore(this.tomorrow)
        ) {
          start = moment().hour(10).minute(30).second(0);
        }

        let count = -1; // Inizializzo un contatore
        let endFirstSlot = null; // inizializzo da dove partire per il secondo set di ore

        const end = this.defaultEndTime.clone().subtract(this.rangeTime, "hour");

        for (
          let m = moment(start);
          m.isSameOrBefore(end);
          m.add(30, "minutes")
        ) {
          count++;
          this.timeSlot1.push(m.format("HH:mm")); // Aggiunge l'orario formattato
          this.timeSlotPass1 = this.timeSlot1; // memorizzo l'attuale time slots in una variabile che mi serve nell'altra componente calendar

          if (count == 11) {
            endFirstSlot = m;
            break;
          }
        }

        /* inizializzo timeSlot2 */
        for (
          let m = moment(endFirstSlot).add(30, "minutes");
          m.isSameOrBefore(this.defaultEndTime);
          m.add(30, "minutes")
        ) {
          this.timeSlot2.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        if (this.timeSlot2.length === 0) {
          // se timeSlot2 è 0 allora timeSlot2 viene ripristinato con i timeSlot1
          this.timeSlot2 = this.timeSlot1;
        }
        if (this.timeSlot1.length === 0 && this.timeSlot2.length === 0) {
          // se non ci sono orari disponibili oggi
          this.isTimeSlotEmpty = true;

          this.timeSlot1 = this.timeSlotDefault1;
          this.timeSlot2 = this.timeSlotDefault2;
        }

        this.timeSlotPass2 = this.timeSlot2;
      }
    },

    inizializeTimeSlotsDefauls() {
      this.timeSlot1 = this.timeSlotDefault1;
      this.timeSlot2 = this.timeSlotDefault2;
    },
    isSelected(day) {
      return day.isSame(this.selectedDay, "day");
    },
    selectDay(day) {
      const now = this.now;
      this.selectedDay = day;
      this.selectedTime = null;

      if (
        day.isSame(moment().add(1, "day"), "day") &&
        now.isAfter(moment().hour(18).minute(0).second(0)) &&
        now.isBefore(moment().add(1, "day").hour(8).minute(0).second(0))
      ) {
        this.timeSlot1 = this.timeSlotPass1;
        this.timeSlot2 = this.timeSlotPass2;
        if (this.timeSlot2.length == 0) {
          this.timeSlot2 = this.timeSlotPass1;
        }
      } else {
        this.timeSlot1 = this.timeSlotDefault1;
        this.timeSlot2 = this.timeSlotDefault2;
        this.isNextTimeSlot = false;
      }
      if (day.isSame(moment(), "day")) {
        this.timeSlot1 = this.timeSlotPass1;
        this.timeSlot2 = this.timeSlotPass2;

        console.log("timeSlot1: " + this.timeSlot1);
        console.log("timeSlot2: " + this.timeSlot2);

        if (this.timeSlot2.length == 0) {
          this.timeSlot2 = this.timeSlotPass1;
        }
      }
    },
    selectTime(slot) {
      this.selectedTime = slot;
    },
    prevDays() {
      if (this.startIndexDays > 0) {
        this.startIndexDays -= this.numberDaysVisible;
      }
    },
    nextDays() {
      if (this.startIndexDays < this.days.length - 5) {
        this.startIndexDays += this.numberDaysVisible; //
      }
    },
    nextTmeSlot() {
      if (!this.isNextTimeSlot) {
        this.isNextTimeSlot = true;
      }
    },
    prevTimeSlot() {
      if (this.isNextTimeSlot) {
        this.isNextTimeSlot = false;
      }
    },
    confirmSelection() {
      if (this.selectedDay && this.selectedTime) {
        const dateWithTime = this.selectedDay.clone().startOf("day"); // Rimuove l'ora da selectedDay

        const [hours, minutes] = this.selectedTime.split(":"); // Estrae l'ora e i minuti da selectedTime

        dateWithTime.set({ hour: parseInt(hours), minute: parseInt(minutes) }); // Imposta l'ora e i minuti su dateWithTime

        const dateTime = dateWithTime.local().format("YYYY-MM-DDTHH:mm[Z]"); // formatto come vuole il backEnd

        this.$emit("date-time", dateTime); // Emit selected date and time to parent component
        this.$emit("is-open", false);
      } else {
        alert("Please select a day and time");
      }
    },

    checkSelection() {
      // Check if selected day and time are valid
      if (this.selectedTime && this.isSelected(this.selectedDay)) {
        this.isSelectedDropTime = true;
      } else {
        this.isSelectedDropTime = false;
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth; // Aggiorna la variabile con la nuova larghezza
      if (this.screenWidth <= 550) {
        this.numberDaysVisible = 4;
      } else {
        this.numberDaysVisible = 5;
      }
    },
  },
  watch: {
    selectedTime() {
      this.checkSelection();
    },
    selectedDay() {
      this.checkSelection();
    },
    screenWidth(newWidth) {
      if (newWidth <= 550) {
        this.numberDaysVisible = 4;
      } else {
        this.numberDaysVisible = 5;
      }
      // Puoi eseguire altre logiche qui, ad esempio aggiornare il layout o altro
    },
  },
  mounted() {
    this.inizializeTimeSlots();

    this.initializeDays();

    this.updateScreenWidth(); // Call updateScreenWidth on window load and on window resize event to handle responsive design properly.

    window.addEventListener("resize", this.updateScreenWidth); // Update screen width on window resize
  },
};
</script>

<style scoped>
h3 {
  color: #1c4c34;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 18px;
}

h3::first-letter {
  text-transform: uppercase;
}
.calendar-template {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
  padding: 30px;

  border-radius: 8px;
  background-color: white;
}

.days-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap:1vw;
}

.day-pc,
.day-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px; /* Imposta una larghezza fissa */
  height: 65px; /* Imposta un'altezza uguale alla larghezza */
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: #ecf5e4;
  padding: 10px;
}

.day-pc p {
  text-align: center;
  font-size: 16px;
}

.day-mobile p {
  text-align: center;
  font-size: 22px;
}

.day-pc.selected,
.day-mobile.selected {
  background-color: #1c4c34;
  color: white;
  font-family: Wotfard-SemiBold;
}

.nav-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.nav-button.disabled {
  color: white;
  cursor: unset;
}

.arrow-img{
  width: 16px;
  height: 16px;
}

.slots-box {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;


  gap: 5%;
}

.img-arrows-slot-box{
  width: 16px;
  height: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding: 2px;
  gap: 10px;
}

.time-slot {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.time-slot-button {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #ecf5e4;
  font-size: 16px;
}

.selected-time {
  background-color: #1c4c34;
  color: white;
  font-family: Wotfard-SemiBold;
}

.select-button {
  width: 90%;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1c4c34;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Wotfard-SemiBold;
}

.select-button:disabled {
  background-color: #92b7a5;
  cursor: unset;
}

@media (max-width: 780px) {

  .time-slot {
    width: 100%;
  }

  .select-button {
    width: 100%;
  }
}

@media (min-width: 380px) and (max-width: 550px) {
  .day-mobile p {
    font-size: 14px;
  }
  .day-pc p {
    font-size: 14px;
  }

}
@media (min-width: 380px) and (max-width: 500px) {
  .time-slot-button {
    font-size: 10px;
  }

  .time-slot {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}

@media (max-width: 379px) {
  .day-mobile p {
    font-size: 14px;
  }

  .nav-button {
    font-size: 8px;
  }

  .time-slot {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }

  .time-slot-button {
    font-size: 10px;
  }
}
</style>
