import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Internationalization
import { createI18n } from 'vue-i18n'
import en from './i18n/en.json';
import it from './i18n/it.json';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import LuxonAdapter from "@date-io/luxon"
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

import 'maz-ui/styles'

import VueGoogleMaps from '@fawmi/vue-google-maps' //google maps api

import Vuex from "vuex";
import { createStore } from 'vuex';

import { createVuestic } from "vuestic-ui";
//import "vuestic-ui/css";
// instead of `import "vuestic-ui/css";`
//import 'vuestic-ui/styles/essential.css';
//import 'vuestic-ui/styles/typography.css';

const icons = {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }

const vuetify = createVuetify({
    icons,
    components,
    directives,
    date: {
        adapter: LuxonAdapter,
        locale: {
            it: 'it-IT',
        }
    },
})

const i18n = createI18n({
  legacy: false,            // legacy false, per accedere dal setup()
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    it
  }
})

const app = createApp(App)

app.use(VueAxios, axios)
app.use(vuetify)
app.use(router)
app.use(i18n)
app.use(Vuex);
app.use(createVuestic());

app.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyDGA_F9qJxjQ3LCK1Xl0Cd30EY5fUYYVGU',
      libraries: 'places' 
      // language: 'de',
  },
})

export const store= createStore({ // Define Vuex store
  state: {  // Define initial state
    nameCity: 'Milano' // Define default city
  },
  mutations: {
    setCityName(state, city) { // Define mutation to update state.nameCity when called
      state.nameCity = city;
    }
  },
  actions: {
    updateCityName(context, city) { // Define action to commit mutation when called
      context.dispatch('setNameCity', city);
    }
  }
});

app.use(store); // Associa lo store Vuex all'app Vue

app.mount('#app')