<template>
  <div class="intro-section-title">
    <div class="intro-title">
      <div class="row-title">
        <h1>
          {{ $t("message.introSection.title1a") }}
        </h1>
        <h1 style="color: #9fcb78">
          {{ $t("message.introSection.title1b") }}
        </h1>
      </div>

      <div class="liberati-dall'ingombro">
        <h1>{{ $t("message.introSection.title2") }}</h1>
      </div>

      <div class="row-title">
        <h1>
          {{ $t("message.introSection.title3a") }}
          <span style="color: #9fcb78"> {{ $t("message.introSection.title3b") }}</span>
        </h1>
        <!-- <h1>
          {{ $t("message.introSection.title3b") }}
        </h1>
        <h1 style="color: #9fcb78">
          {{ $t("message.introSection.title3c") }}
        </h1>
        <h1 style="color: #9fcb78">
          {{ $t("message.introSection.title3d") }}
        </h1>
        <h1 style="color: #9fcb78">
          {{ $t("message.introSection.title4") }}
        </h1> -->
      </div>
    </div>
    <p class="intro-subtitle">{{ $t("message.introSection.subtitle") }}</p>
  </div>
</template>

<script></script>

<style>
.intro-section-title {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: start;

  text-align: start;

  padding-left: 3vw;
}

.intro-section-title p{
  font-size: 30px;
  font-family: wotfard-regular-webfont;
}

.intro-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 1vw; */
}

.intro-title h1 {
  font-family: Wotfard-Bold;
  padding: 0;
  font-size: 60px;
}

.intro-section-title p {
  width: 100%;
  text-align: start;
  font-size: 26px;

  font-family: wotfard-regular-webfont;
  padding: 10px 0 0 0;
}

.row-title{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin:0;
    gap: 1vw;
  }

.intro-title {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {

  .intro-section-title{
    padding: 20px;
  }
  .intro-section-title p {
    font-size: 24px;
  }
  .intro-title h1 {
    font-size: 60px;
  }
  .intro-subtitle {
    color: white;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .intro-title {
    color: white;
    font-size: 30px;
  }
  .intro-title h1 {
    font-size: 28px;
  }
  .row-title {
    gap: 10px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
  .intro-title {
    color: white;
  }
  .intro-title h1 {
    font-size: 50px;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {

  .intro-section-title{
    padding: 30px;
  }
  .intro-title h1 {
    font-size: 30px;
  }

  .intro-section-title p {
    font-size: 24px;
  }
  
  .row-title{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin:0;
  }

  .row-title h1{
    word-wrap: break-word; /* Spezza le parole lunghe quando necessario */
  overflow-wrap: break-word; /* Permette anche la rottura di parole troppo lunghe */
  text-align: start; /* (Opzionale) Puoi centrare il testo se lo desideri */
  }

  .row-title .last-two {
    flex-basis: 100%; /* Forza i due h1 a occupare il 100% della larghezza e quindi ad andare a capo */
  }
}
</style>
