<template>
  <div class="our-values-template" v-if="!isMobile">
    <h1>
      {{ $t("message.ourValuesSection.title0") }}
      <span style="color: #9fcb78">
        {{ $t("message.ourValuesSection.title1") }}</span
      >
    </h1>
    <div class="card-values">
      <div class="card">
        <h3>{{ $t("message.ourValuesSection.safeness.title") }}</h3>
        <p>
          <span>{{ $t("message.ourValuesSection.safeness.paragraph1") }}</span
          >&nbsp;
          <span style="font-weight: bold; font-family: Wotfard-SemiBold">{{
            $t("message.ourValuesSection.safeness.paragraph1a")
          }}</span>
        </p>
        <p>
          <span>{{ $t("message.ourValuesSection.safeness.paragraph2") }}</span>
        </p>
        <p>
          <span
            >{{
              $t("message.ourValuesSection.safeness.paragraph3")
            }}&nbsp;</span
          >
          <span style="font-weight: bold; font-family: Wotfard-SemiBold">{{
            $t("message.ourValuesSection.safeness.paragraph3a")
          }}</span>
        </p>
      </div>
      <div class="card">
        <h3>{{ $t("message.ourValuesSection.affordability.title") }}</h3>
        <p>
          <span
            >{{
              $t("message.ourValuesSection.affordability.paragraph1")
            }}&nbsp;</span
          >
          <span style="font-weight: bold; font-family: Wotfard-SemiBold">
            {{ $t("message.ourValuesSection.affordability.paragraph1a") }}</span
          >
        </p>
        <p>{{ $t("message.ourValuesSection.affordability.paragraph2") }}</p>
      </div>
      <div class="card">
        <h3>{{ $t("message.ourValuesSection.reliability.title") }}</h3>
        <p>{{ $t("message.ourValuesSection.reliability.paragraph1") }}</p>
        <p>{{ $t("message.ourValuesSection.reliability.paragraph2") }}</p>
        <p>{{ $t("message.ourValuesSection.reliability.paragraph3") }}</p>
      </div>
    </div>
  </div>

  <!--  ----------lato mobile -------------- -->

  <div class="swiper-box" v-if="isMobile">
    <h1>
      {{ $t("message.ourValuesSection.title0") }}
      <span style="color: #9fcb78">
        {{ $t("message.ourValuesSection.title1") }}
      </span>
    </h1>

    <swiper
      :slidesPerView="'auto'"
      :spaceBetween="30"
      :pagination="{
        dynamicBullets: true,
      }"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide>
        <div class="card-mobile">
          <h3>{{ $t("message.ourValuesSection.safeness.title") }}</h3>
          <p>
            <span>{{ $t("message.ourValuesSection.safeness.paragraph1") }}</span
            >&nbsp;
            <span style="font-weight: bold; font-family: Wotfard-SemiBold">{{
              $t("message.ourValuesSection.safeness.paragraph1a")
            }}</span>
          </p>
          <p>
            <span>{{
              $t("message.ourValuesSection.safeness.paragraph2")
            }}</span>
          </p>
          <p>
            <span
              >{{
                $t("message.ourValuesSection.safeness.paragraph3")
              }}&nbsp;</span
            >
            <span style="font-weight: bold; font-family: Wotfard-SemiBold">{{
              $t("message.ourValuesSection.safeness.paragraph3a")
            }}</span>
          </p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="card-mobile">
          <h3>{{ $t("message.ourValuesSection.affordability.title") }}</h3>
          <p>
            <span
              >{{
                $t("message.ourValuesSection.affordability.paragraph1")
              }}&nbsp;</span
            >
            <span style="font-weight: bold; font-family: Wotfard-SemiBold">
              {{
                $t("message.ourValuesSection.affordability.paragraph1a")
              }}</span
            >
          </p>
          <p>{{ $t("message.ourValuesSection.affordability.paragraph2") }}</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="card-mobile">
          <h3>{{ $t("message.ourValuesSection.reliability.title") }}</h3>
          <p>{{ $t("message.ourValuesSection.reliability.paragraph1") }}</p>
          <p>{{ $t("message.ourValuesSection.reliability.paragraph2") }}</p>
          <p>{{ $t("message.ourValuesSection.reliability.paragraph3") }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style>
.our-values-template {
  width: 100%;
  height: auto;
  background-color: #1c4c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 3vh;
}

.our-values-template h1 {
  color: white;
  width: 100%;
  text-align: center;
  margin: 4vh 0 8vh 0;
  padding: 0;
  font-size: 40px;
  line-height: 0.8;
  font-family: wotfard-Bold;
}

.card-values {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: space-around;
  justify-content: center;
  gap: 5%;

  margin: 0 0 10vh 0;
}

.card {
  flex: 25%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 30px;
  border-radius: 10px;

  gap: 20px;

  background-color: white;
}

.card h3 {
  font-size: 24px;
  color: #3c3c3c;
  position: relative;
  display: inline-block; /* Importante per assicurarsi che la larghezza dell'elemento sia corretta */

  z-index: 1;
}

.card h3::after {
  content: ""; /* Pseudo-elemento che rappresenta la sottolineatura */
  position: absolute;
  left: 0;
  bottom: 5px; /* Sposta la linea verso l'alto di 5px rispetto alla posizione normale */
  width: 110%;
  height: 5px; /* Spessore della "sottolineatura" */
  border-radius: 3px;
  background-color: #9fcb788f; /* Colore della "sottolineatura" */
  z-index: 0; /* Metti l'evidenziatura dietro al testo */
}

.row-p {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.card p {
  font-size: 16px;
  color: #3c3c3c;
  text-align: start;
  padding: 0;
  margin: 0;
}

@media (min-width: 800px) and (max-width: 1000px) {
  .our-values-template {
    height: 85vh;
  }

  .card {
    gap: 1vh;

    padding: 2.1vw;
  }

}

/* --------------------- ------------mobile app ----------- ------------------------ */
.swiper-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  background-color: #1c4c34;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-box h1 {
  color: white;
  width: 100%;
  text-align: center;
  margin: 5vh 0 5vh 0;
  padding: 0 0 0 0;
  font-size: 24px;
  line-height: 0.8;
  font-family: wotfard-Bold;
}

.swiper {
  width: 100%;
  height: 100%;
  background-color: #1c4c34;
  display: flex;
  flex-direction: row;
}

.swiper-slide {
  width: 75%;
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-mobile {
  width: 82%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 2rem;
  border-radius: 10px;

  gap: 20px;

  background-color: white;
}

.card-mobile h3 {
  font-size: 26px;
  margin: 0;

  color: #3c3c3c;
  position: relative;
  display: inline-block; /* Importante per assicurarsi che la larghezza dell'elemento sia corretta */

  font-family: wotfard-Bold;
  z-index: 1;
}

.card-mobile h3::after {
  content: ""; /* Pseudo-elemento che rappresenta la sottolineatura */
  position: absolute;
  left: 0;
  bottom: 5px; /* Sposta la linea verso l'alto di 5px rispetto alla posizione normale */
  width: 110%;
  height: 5px; /* Spessore della "sottolineatura" */
  border-radius: 3px;
  background-color: #9fcb788f; /* Colore della "sottolineatura" */
  z-index: 0; /* Metti l'evidenziatura dietro al testo */
}

.card-mobile p {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 480px) {
  .swiper-box {
    height: 70vh;
  }

  .swiper-box h1{
    margin: 3vh 0 1.8vh 0;
  }
  .card-mobile {
    padding: 24px;
  }
  .card-mobile h3 {
    font-size: 24px;
    margin: 0;
  }

  .card-mobile p {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
}
</style>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { ref } from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth); //resize è l'evento che accade quando la finestra viene ridimensionata dall'utente
  },
  setup() {
    const windowWidth = ref(null);
    const isMobile = ref(false);

    const checkWindowWidth = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value < 800) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    };
    return {
      modules: [Pagination],
      checkWindowWidth,
      windowWidth,
      isMobile,
    };
  },
  watch: {
    windowWidth() {
      this.checkWindowWidth(); // Ogni volta che windowWidth cambia, controlla
    },
  },
};
</script>
