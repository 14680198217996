<template>
  <div class="baggy-pages" >
    
      <div class="first-row">
        <img src="@/assets/logo-introsection.png" alt="" />

        <div class="component-intro-section">
          <IntroSection></IntroSection>
        </div>

        <div class="book-component">
          <BookingSection> </BookingSection>
        </div>
      </div>

      <div class="discount-component">
          <DiscountSection> </DiscountSection>
      </div>

      <div class="second-row">
        <a id="how-it-works" />
        <HowItWorksSection> </HowItWorksSection>
      </div>
   

    <div class="baggy-bottom-section">
      <div class="our-values">
        <a id="our-values" />
        <OurValuesSection> </OurValuesSection>
      </div>

      <v-row class="become-partner">
        <a id="become-partner" />
        <BecomePartnerSection> </BecomePartnerSection>
      </v-row>
    </div>
  </div>
  <v-row class="faq">
    <a id="faq" />
    <v-col no-gutters lg="12" md="12" sm="12">
      <FAQSection> </FAQSection>
    </v-col>
  </v-row>

  <AreYouReady></AreYouReady>
  <FooterSection></FooterSection>
</template>

<script>
import IntroSection from "@/components/IntroSection";
import BookingSection from "@/components/BookingSection";
import DiscountSection from "@/components/DiscountSection";
import OurValuesSection from "@/components/OurValuesSection";
import HowItWorksSection from "@/components/HowItWorksSection";
import BecomePartnerSection from "@/components/BecomePartnerSection";
import FAQSection from "@/components/FAQSection";
import AreYouReady from "@/components/AreYouReady.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  name: "HomeView",
  components: {
    IntroSection,
    BookingSection,
    DiscountSection,
    OurValuesSection,
    HowItWorksSection,
    BecomePartnerSection,
    FAQSection,
    AreYouReady,
    FooterSection
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      console.log(top);
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.baggy-pages{
  margin-top: 5%;
  overflow: hidden;
}
.grid-section-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.first-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

}

.first-row img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 55%;
  z-index: 0;
}

.component-intro-section {
  width: 65%;
  height: 100vh;

  display: flex;
  align-items: center;
  margin-bottom: 20%;
}

.book-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

/*   margin-right: 5%;
 */  padding-bottom: 20%;
  z-index: 1;
  
  width: 40%;
}

.discount-component {
  background-color: #1c4c34;
}

#logo-img {
  width: 100px;
}

.left-section {
  color: #1c4c34;
}

.v-banner {
  position: static;
  font-size: 20px;
  padding: 0;
}

.faq {
  justify-content: left;
  background-color: #1c4c34;
  margin: 0px;
  overflow-y: scroll;
}

.left-section .bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  --background: url("~@/assets/bagagli.jpg");
  background-size: cover;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.left-section p {
  text-align: left;
}


@media (width: 540px) and (height: 720px) {
  .component-intro-section {
    height: 75vh;
    background-color: aliceblue
  }
}

@media (min-width: 1350px) and (max-width: 1550px){
  .book-component{
    /* margin-right: 5%; */
  }
  
}

@media (min-width: 1000px) and (max-width: 1349px){
  .book-component{
    margin-right: 0;
  }
  
}
@media (max-width: 1000px) {

  .first-row {
    width: 100%;
    height: 130vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: #9fcb78;

  }
  .first-row img {
    display: none;
  }

  .component-intro-section {
    width: 100%;
    height: 60vh;
    z-index: 0;
    margin: 2vh 0 -18px 0;
    background-color: #1c4c34;
    padding-bottom: 10%;;
  }

  .book-component{
    width: 100%;
    height: 70%;
    margin: 0;
    padding: 0;
    z-index: 1;
    background-color: #9fcb78;

    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }
  .grid-section-home {
    display: block;
  }
}


@media (min-width: 400px) and (max-width: 599px) and (min-height: 0px) and (max-height: 719px),
       (min-width: 400px) and (max-width: 599px) and (min-height: 721px){
  .component-intro-section{
    height: 55vh;
    align-items: start;
    padding: 12vw 0 0 0;
  }
  .book-component {
    height: 60vh;
  }

  .discount-component {

    padding-top:2vw;
  }
  
}

@media (min-width: 0px) and (max-width: 399px) {
  .first-row {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: #9fcb78;

  }
  .first-row img {
    display: none;
  }

  .component-intro-section {
    width: 100%;
    height: 65vh;
    z-index: 0;

    align-items: start;
    padding: 12vw 0 0 0;
    /* margin: 0 0 -18px 0;
    padding: 0 0 18vw 4vw; */
    background-color: #1c4c34;
  }

  .book-component{
    width: 100%;
    height: 75vh;
    margin: 0;
    padding: 0;
    z-index: 1;
    background-color: #9fcb78;

    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }
  .grid-section-home {
    display: block;
  }
  .baggy-discount {
    font-size: 15px;
  }  
}

@media (min-width: 480px) {
  .v-banner-resize {
    padding-left: 200px;
  }
}

@media (min-width: 600px) {
  .v-banner-resize {
    padding-left: 200px;
  }
}
</style>
