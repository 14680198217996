<template>
  <div class="about">
    <h1>Payment successfull</h1>
      <p>{{ $t("message.SuccessView.paragraph1") }}</p>
      <p>{{ $t("message.SuccessView.paragraph2") }}</p>
      <v-btn color="primary" @click="goToHome">
        {{ $t("message.SuccessView.button") }}
      </v-btn>
  </div>
</template>

<script>

export default {
  name: 'CancelledView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.about {
  text-align: center;
  margin-top: 50px;
}

h1 {
  color: #2c3e50;

}

p {
    color: #2c3e50;
    margin-bottom: 20px;
  }
</style>