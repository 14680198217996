<template>
    <div class="booking-section">
      <h2>   {{ $t("message.booking.areyouready1") }} <span style="color: #9fcb78;">   {{ $t("message.booking.areyouready2") }}</span>?</h2>
      <a class="prenota-ora" @click="goto('book')">
        {{ $t("message.footer.buttonBook") }}
      </a>
  
      <img src="@/assets/kids-cta-bottom.png" alt="" class="img-kids" />
    </div>

  </template>
  
  <script>
  export default {
    name: "FooterSection",
  
    data: () => ({
      drawer: false,
      group: null,
      dialogPrivacyPolicy: false,
      dialogCoockiPolicy: false,
      dialogThermsConditions: false,
    }),
  
    components: {
      
    },
    watch: {
      group() {
        this.drawer = false;
      },
    },
    methods: {
      goto(refName) {
        var element = document.getElementById(refName).offsetTop - 80;
        window.scrollTo(0, element);
        this.drawer = false;
      },
      changeLocale(locale) {
        console.log("changing locale to: ", locale);
        this.$i18n.locale = locale;
      },
    },
  };
  </script>
  
  <style>
  h2 {
    width: 80%;
    margin-top: 50px;
    font-size: 52px;
    font-weight: bold;
  
    color: #1c4c34;
  }
  
  h3 {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #9fcb78;
  }
  
  li {
    list-style: none; /* Rimuove il puntino */
    padding-left: 0; /* Rimuove l'indentazione */
  }
  
  .contact-section u {
    cursor: none;
    line-height: 25px;
    font-family: wotfard-regular-webfont;
  }
  u {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  
  .menu a {
    text-decoration: none;
    color: white;
  }
  
  .booking-section {
    position: relative; /* Questo rende la sezione il contenitore di riferimento per i figli con posizione assoluta */
  
    width: 100%;
    height: 50vh;
  
    text-align: center;
  
    display: flex;
    flex-direction: column;
    align-items: center;
  
    gap: 10px;
  }
  
  .booking-section h2{
    font-family: Wotfard-Bold;
    font-size: 40px;
  }
  .prenota-ora {
    background-color: #1c4c34;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 10px 22px 10px 22px;
    font-family: wotfard-Bold;
  }
  
  .img-kids {
    width: 290px;
  
    position: absolute;
    top: 130px; /* regola la posizione in base alle tue necessità */
    left: 70%;
    transform: translateX(-50%);
    z-index: 1; /* Valore alto per portare l'immagine in primo piano */
  }
  
  /* per desktop */
  @media (max-width: 600px) {
    h2 {
      width: 100%;
      font-size: 7vw; /* Font-size più piccolo sui dispositivi più grandi */
    }
  
    .booking-section{
        height: 30vh;
    }
    .prenota-ora {
      padding: 10px 20px 10px 20px;
      font-size: 14px;
    }
  
    .img-kids{
      display: none;
    }
  
  }
  
  @media (max-width: 480px) {
    h2 {
      width: 100%;
      font-size: 8vw;
    }
  
    .booking-section h2{
      font-size: 24px;
    }
    .prenota-ora {
      padding: 10px 20px 10px 20px;
    }
  
    .first-block-footer-section {
      flex-direction: column;
    }    
  }
  </style>
  