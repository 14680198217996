<template>
  <v-layout class="layout d-flex flex-column" id="app" theme="light">

    <NavigationSection></NavigationSection>
    
      <router-view/>
  </v-layout>
</template>

<script>
import NavigationSection from '@/components/NavigationSection'

export default {
  name: 'App',
  components: {
    NavigationSection,
  }
}
</script>

<style>

html {
  height: 100%;
}

#app {
  font-family: wotfard-regular-webfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


#app-bar {
  padding-left: 0px;
  padding-right: 50px;
}

#logo-img {
  width: 100px;
}

#footer {
  min-height: 100px;
}


@font-face {
    font-family: wotfard-regular-webfont;
    src: url('~@/assets/fonts/wotfard-regular-webfont.ttf');
}
@font-face {
    font-family: Wotfard-SemiBold;
    src: url('~@/assets/fonts/Wotfard-SemiBold.ttf');
}
@font-face {
    font-family: Wotfard-SemiBoldItalic;
    src: url('~@/assets/fonts/Wotfard-SemiBoldItalic.ttf');
}
@font-face {
    font-family: Wotfard-Bold;
    src: url('~@/assets/fonts/Wotfard-Bold.ttf');
}
@font-face {
    font-family: Wotfard-BoldItalic;
    src: url('~@/assets/fonts/Wotfard-BoldItalic.ttf');
}
</style>
