<template>

  <!--  ----------lato desktop -------------- -->
    <v-container v-if="!isMobile">
        <section class="discount-section v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
            <v-row>
                <v-col-9>
                    <v-row class="discount-first" >
                        <v-col>
                            <h1 class="discount-title"> {{ $t("message.discountSection.title1") }} </h1>
                        </v-col>
                    </v-row>
                    <v-row class="discount-banner" >
                        <v-col>
                            <h1 class="discount-title"> {{ $t("message.discountSection.title2") }} </h1>
                        </v-col>
                    </v-row>
                    <v-row class="discount-description" >
                        <v-col>
                            <h1 class="discount-title"> {{ $t("message.discountSection.subtitle") }} </h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn class="discount-button" v-bind="activatorProps" variant="flat" @click="goto('book')">
                                {{$t('message.discountSection.buttonTry')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col-9>
                <v-col-3>
                    <img src="@/assets/crazy.png" alt="" class="discount-img" />
                </v-col-3>
            </v-row>
        </section>
</v-container>


  <!--  ----------lato mobile -------------- -->
<v-container v-if="isMobile">
        <section class="discount-section-mobile v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
            <v-row class="discount-first-mobile" >
                <v-col>
                    <h1 class="discount-title-mobile"> {{ $t("message.discountSection.title1") }} </h1>
                </v-col>
            </v-row>
            <v-row class="discount-banner-mobile" >
                <v-col>
                    <h1 class="discount-title-mobile" style="padding: 3px;"> {{ $t("message.discountSection.title2") }} </h1>
                </v-col>
            </v-row>
            <v-row class="discount-description-mobile" >
                <v-col>
                    <h1 class="discount-title-mobile"> {{ $t("message.discountSection.subtitle") }} </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="discount-button-mobile" v-bind="activatorProps" variant="flat" @click="goto('book')">
                        {{$t('message.discountSection.buttonTry')}}
                    </v-btn>
                </v-col>
            </v-row>
        </section>
</v-container>

</template>


<style>

/*lato desktop */

.discount-section {
    height:350px;
    .discount-first {
        .discount-title {
            color:white;
        }
    }

    .discount-banner {
        .discount-title {
            background-color:#9fcb78; 
            width: 100%;
            color: #1c4c34; 
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            font-family: Wotfard-Bold;
            padding: 5px;
        }
    }

    .discount-description {
        color: #9fcb78; 
        .discount-title {
            font-size: 20px;
        }
    }

    .discount-button {
        background-color: white;
        font-family: wotfard-Bold;
        color: #1c4c34; 
        width:300px;
        height: 50px !important;
        font-weight: bold;
    }

    .discount-img {
        width: 400px;
        height: 346px;
    }
}

.discount-section-mobile {
    height:400px;
    .discount-first-mobile {
        .discount-title-mobile {
            font-size:24px;
            color:white;
            font-family: wotfard-Bold;
        }
    }

    .discount-banner-mobile {
        .discount-title-mobile {
            background-color: #9fcb78; 
            font-family: wotfard-Bold;
            font-size:22px;
            width: 100%;
            color: #1c4c34; 
            border-radius: 12px;
            text-align: center;
        }
    }

    .discount-description-mobile {
        color: #9fcb78; 
        .discount-title-mobile {
            font-size: 15px;
        }
    }

    .discount-button-mobile {
        margin-top:50px;
        background-color: white;
        font-family: wotfard-Bold;
        color: #1c4c34; 
        width:100%;
        height: 50px !important;
        font-weight: bold;
    }
}

/*lato mobile */



</style>


<script>
import { ref } from "vue";
import { Pagination } from "swiper/modules";

    export default {
        name: "DiscountSection",
        methods: {
            goto(refName) {
                var element = document.getElementById(refName).offsetTop - 80;
                window.scrollTo(0, element);
            },
        },
        mounted() {
            this.checkWindowWidth();
            window.addEventListener("resize", this.checkWindowWidth); //resize è l'evento che accade quando la finestra viene ridimensionata dall'utente
        },
        setup() {
            const windowWidth = ref(null);
            const isMobile = ref(false);

            const checkWindowWidth = () => {
            windowWidth.value = window.innerWidth;
            if (windowWidth.value < 800) {
                isMobile.value = true;
            } else {
                isMobile.value = false;
            }
            };
            return {
            modules: [Pagination],
            checkWindowWidth,
            windowWidth,
            isMobile,
            };
        },
        watch: {
            windowWidth() {
            this.checkWindowWidth(); // Ogni volta che windowWidth cambia, controlla
            },
        },
    };

</script>